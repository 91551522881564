import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

const usePreserveScroll = () => {
  const router = useRouter()
  const scrollPositions = useRef<{ [url: string]: number }>({})
  const isBack = useRef(false)

  useEffect(() => {
    let timeOut
    window.history.scrollRestoration = 'manual'

    router.beforePopState(() => {
      isBack.current = true
      return true
    })

    const onRouteChangeStart = () => {
      if (window.scrollY === 0) return

      const [url] = router.asPath.split('?')
      scrollPositions.current[url] = window.scrollY
    }

    const onRouteChangeComplete = (fullUrl: any) => {
      const [url] = fullUrl.split('?')
      if (isBack.current && scrollPositions.current[url]) {
        setTimeout(() => {
          window.scroll({
            top: scrollPositions.current[url],
            behavior: 'auto',
          })
        }, 420)
      }

      isBack.current = false
    }

    router.events.on('routeChangeStart', onRouteChangeStart)
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart)
      router.events.off('routeChangeComplete', onRouteChangeComplete)
      clearTimeout(timeOut)
    }
  }, [router])
}

export default usePreserveScroll
