export enum Navigation {
  ACCOUNT_CLICK = 'Navigation.ACCOUNT_CLICK',
  BANNER_LINK_CLICK = 'Navigation.BANNER_LINK_CLICK',
  CART_DROP_DOWN_CLICK = 'Navigation.CART_DROP_DOWN_CLICK',
  CART_DROP_DOWN_OPENED = 'Navigation.CART_DROP_DOWN_OPENED',
  CART_DROP_DOWN_X_CLICK = 'Navigation.CART_DROP_DOWN_X_CLICK',
  CART_DROP_DOWN_CLOSED = 'Navigation.CART_DROP_DOWN_CLOSED',
  CLOSE = 'Navigation.CLOSE',
  OPEN = 'Navigation.OPEN',
  DROP_DOWN_OPENED = 'Navigation.DROP_DOWN_OPENED',
  DROP_DOWN_BACK_CLICK = 'Navigation.DROP_DOWN_BACK_CLICK',
  FEATURED_CLICK = 'Navigation.FEATURED_CLICK',
  LINK_CLICK = 'Navigation.LINK_CLICK',
  ROTATING_BANNER_CLICK = 'Navigation.ROTATING_BANNER_CLICK',
  ROTATING_BANNER_SIGN_UP_CLICK = 'Navigation.ROTATING_BANNER_SIGN_UP_CLICK',
  FOOTER_LIVE_CHAT_CLICK = 'Navigation.FOOTER_LIVE_CHAT_CLICK',
}

export default Navigation
