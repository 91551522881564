export enum Homepage {
  EDITORIAL_TILE_CLICK = 'Homepage.EDITORIAL_TILE_CLICK',
  CATEGORY_MODULE_CLICK = 'Homepage.CATEGORY_MODULE_CLICK',
  BESTSELLER_CLICK = 'Homepage.BESTSELLER_CLICK',
  HERO_CLICK = 'Homepage.HERO_CLICK',
  SUBHERO_CLICK = 'Homepage.SUBHERO_CLICK',
  UGC_WIDGET_CLICK = 'Homepage.UGC_WIDGET_CLICK',
  SMALL_SUSTAINABILITY_MODULE_CLICK = 'Homepage.SMALL_SUSTAINABILITY_MODULE_CLICK',
  LARGE_SUSTAINABILITY_MODULE_CLICK = 'Homepage.LARGE_SUSTAINABILITY_MODULE_CLICK',
  BESTSELLER_NEWARRIVAL_MODULE_CLICK = 'Homepage.BESTSELLER_NEWARRIVAL_MODULE_CLICK',
  REVIEWS_MODULE_CLICK = 'Homepage.REVIEWS_MODULE_CLICK',
}

export default Homepage
